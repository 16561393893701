<template>
  <div id="data-cashcards">

    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" title="CashCards">
      <template v-slot:right-end>
        <div class="vx-row">

          <vs-checkbox class="pr-3" v-model="favCardsToggle">{{ $t('Favourites') }}</vs-checkbox>
          <vs-button class="alpha-clear" v-if="selectedAlpha" color="warning" type="flat"
                     icon-pack="feather" icon="icon-x" size="small"
                     @click="clearSelectedAlpha()"></vs-button>
          <vs-pagination-modified v-model="selectedAlphaCurrentX" :total="26" :max="26"
                                  v-on:change="pageChanged"></vs-pagination-modified>
        </div>
      </template>
    </breadcrumb-base>


    <transition name="fade">
      <div class="vs-row">
        <div class="vs-col px-4 py-3 flex md:w-full">
          <div class="vs-row w-full">
            <vs-input class=" m-5 w-full is-label-placeholder pr-12" icon-pack="feather"
                      icon="icon-search"
                      :placeholder="$t('Search')" v-model="searchText"/>


            <template v-if="favCardsToggle">
              <div class="vs-row px-5 w-full pr-8 flex justify-between" style="place-items: center">
                <h3 class="py-2"><strong>{{ $t('Favourites') }}</strong></h3>
                <div class="vs-col px-4" v-if="configQuantity > 0">

                  <div class="py-2 bg-dark-color shadow-md rounded vx-row  card-billing-text">
<!--                    <div class="vs-col flex px-2" style="place-items: center;">-->
<!--                      <p>{{ $t('BuyingPrice') }} <span-->
<!--                        class="card-billing-info">{{ (totalBill - profit)| germanNumberFormat }}</span></p>-->
<!--                    </div>-->
<!--                    <div class="vs-col flex px-2" style="place-items: center;">-->
<!--                      <p>{{ $t('BuyingDiscount') }} <span class="card-billing-info">{{ discount }} %</span>-->
<!--                      </p>-->
<!--                    </div>-->
                    <div class="vs-col flex" style="place-items: center;">
                      <p>{{ $t('SellingPrice') }} <span class="card-billing-info">{{
                          totalBill | germanNumberFormat
                        }}</span>
                      </p>
                    </div>
<!--                    <div class="vs-col flex px-2" style="place-items: center;">-->
<!--                      <p>{{ $t('Profit') }} <span class="card-billing-info">{{ profit | germanNumberFormat }}</span>-->
<!--                      </p>-->
<!--                    </div>-->
                  </div>
                </div>
                <div
                  v-if="activeUserInfo.internal_role == 0">
                  <vs-button v-if="editFav"
                             class="ml-4"
                             @click="editFav = false" color="danger">
                    <strong>{{ $t('Cancel') }}</strong>
                  </vs-button>
                  <vs-button v-if="editFav"
                             color="secondary"
                             @click="updateFavSequence()"
                             class="ml-4">
                    <strong>{{ $t('Update') }}</strong>
                  </vs-button>
                  <vs-button v-if="!editFav"
                             color="secondary"
                             class="ml-4"
                             @click="editFav = true">
                    <strong>{{ $t('EditFavourites') }}</strong>
                  </vs-button>
                </div>
              </div>
              <transition name="fade">
                <div class=" flex w-full bg-img" v-if="favCashCards.length === 0">
                  <div
                    class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">

                    <h3 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h3>
                  </div>
                </div>
              </transition>
              <div class="vs-col px-4 py-2 flex md:w-full">
                <div class="vs-row w-full">
                  <template v-for="(card, index) in favCashCards">
                    <div class=" w-full md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/3 vs-col">
                      <vx-card :draggable="editFav" class="mb-1 mt-2 mr-4 fav-custom-card"
                               v-bind:key="index"
                               @drop="moveCard($event, index)"
                               @dragover.prevent
                               @dragenter.prevent
                               @dragstart="pickUpCard($event, index)"
                               style="width: auto"
                      >
                        <div class="vx-row text-center px-2" style="place-items: center;margin: auto;">
                          <img class="w-24 h-24 no-drag" :src="'https://e-aufladen.de' + card.product_image"
                               :alt="card.product_name"
                               draggable="false"
                               @dragstart.prevent
                               style="object-fit: contain;margin: 6px 0px;"/>

                          <div class="tooltip" v-if="card.product_configurations[0].info">
                            <h1 class="item-card-text custom-card-value ml-4">{{ card.product_configurations[0].value | germanNumberFormat }}</h1>
                            <span class="tooltiptext tooltip-top">{{ card.product_configurations[0].info }}</span>
                          </div>
                          <h1 v-else class="item-card-text custom-card-value ml-2"
                              style="margin: 6px 0px; color: black; font-size: 12px !important;">
                            {{ card.product_configurations[0].value | germanNumberFormat }}</h1>

<!--                          <vs-spacer></vs-spacer>-->
                          <vs-button @click="removeQuantity(card.product_configurations[0].id)"
                                     class="mx-1 custom-button small-button" size="normal"
                                     color="danger"
                                     type="filled"
                                     icon-pack="feather" icon="icon-minus"></vs-button>
                          <div class="flix-input-holder">
                            <div class="">
                              <input v-model="selectedConfig == card.product_configurations[0].id ? configQuantity : 0"
                                     :id="'config_' + card.product_configurations[0].id"
                                     type="text"
                                     placeholder="0" class="flix-input-field custom-input"
                              >
                            </div>
                          </div>
                          <!--                      <h4 class="item-card-amount">{{ selectedConfig == config.id ? configQuantity : 0 }}</h4>-->
                          <vs-button @click="addQuantity(card.product_configurations[0])"
                                     class="mx-1 custom-button small-button" size="normal"
                                     color="success" type="filled"
                                     icon-pack="feather" icon="icon-plus"></vs-button>
                          <vs-button :disabled="selectedConfig != card.product_configurations[0].id"
                                     color="secondary"
                                     size="normal"
                                     style="padding: .5rem 2rem;"
                                     class="ml-2 px-4 " @click="purchaseCard()">
                            <strong>{{ $t('Buy') }}</strong>
                          </vs-button>
                          <vs-button
                            class=" mx-1"
                            radius color="danger"
                            :key="card.product_configurations[0].id"
                            type="flat"
                            :icon="card.product_configurations[0].favorite == 1 ? 'favorite' : 'favorite_border'"
                            @click="removeAddFromFav(card.product_configurations[0].favorite == 1 ? 0 : 1, card.product_configurations[0])"
                            size="large"></vs-button>
                        </div>

                      </vx-card>
                    </div>
                  </template>
                </div>
              </div>


            </template>

            <!--    Empty List State -->
            <transition name="fade">
              <div class="py-6 flex w-full bg-img" v-if="cashCards.length === 0 && !errorFetching">
                <div
                  class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--                  <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                       class="mx-auto mb-4 max-w-full">-->
                  <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
                </div>
              </div>
            </transition>

            <div class="vs-col px-4 py-3 flex md:w-full">

              <div class="vs-row w-full">
                <template v-for="(card, index) in cashCards">
                  <div class=" w-full md:w-1/3 lg:w-1/5 xl:w-1/5 2xl:w-1/6 vs-col px-2 pb-4">
                    <vx-card class="cursor-pointer custom-card" style="" @click="openConfigsPopUp(card)">
                      <img class="card-image" :src="card.product_image"
                           :alt="card.product_name"/>
                    </vx-card>
                    <p class="text-center pt-2"><strong>{{ card.product_name }}</strong></p>
                  </div>
                </template>
                <!-- <template v-if="loadingMoreProducts">
                  <div
                    class="w-full sm:w-1/2 md:w-1/4 lg:w-1/6 xl:w-1/6 2xl:w-1/8 text-center vs-col"
                    v-bind:key="'loading_' + index" v-for="(val, index) in 4">
                    <div class="h-full border-2 border-gray-200 rounded-lg overflow-hidden mb-1 mt-2 mr-4">
                      <div class="bg-gray-400 h-32 w-full object-cover object-center"></div>
                      <div class="p-4">
                        <h2 class="bg-gray-400 animate-pulse h-4 w-1/2 mb-2"></h2>
                      </div>
                    </div>
                  </div>
                </template> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div>
      <vs-sidebar v-if="showConfigsActive" click-not-close position-right parent="body"
                  default-index="1" color="primary" class="cash-cards-side-bar custom-card-sidebar
                  items-no-padding" spacer v-model="showConfigsActive">
        <!--      <vx-modal v-if="showConfigsActive" @close="closedShowConfigs" component-class="modal-container-7">-->
        <div class="mt-6 flex items-center justify-between px-6">
          <vs-avatar color="danger" @click="closedShowConfigs()" size="large" icon="clear">
          </vs-avatar>
          <!--          <feather-icon icon="XIcon" class="cursor-pointer"></feather-icon>-->
        </div>

        <div id="cash-cards-sidebar">
          <div class="p-6 flex flex-centered" style="overflow-y: scroll;height: calc(100vh - 100px);">
            <div class="vs-row justify-center col-mx-auto  w-full">
              <div class="vx-col text-center">
                <img class="modal-card-image"
                     :src="'https://e-aufladen.de' + selectedProduct.product_image"
                     :alt="selectedProduct.product_name"/>
                <div class="p-3"></div>
              </div>
              <div class="vs-row">
                <div class="vs-col mx-3 single-cash-card"
                     v-for="(config, index) in selectedProduct.product_configurations">
                  <vx-card class="mb-1 mt-1 mr-5 custom-single-card"
                           v-bind:class="{ 'product-selected': selectedConfig != null && selectedConfig.id === config.id }"
                           @click="selectedConfig = config; calculateBill()">
                    <div class="vx-row vs-align-center text-center justify-center" style="place-items: center">
                      <div class="tooltip" v-if="config.info">
                      <h1 class="mx-3 item-card-text ">{{ config.value | germanNumberFormat }}</h1>
                      <span class="tooltiptext tooltip-top">{{ config.info }}</span>
                    </div>
                    <h1 v-else class="mx-3 item-card-text">{{ config.value | germanNumberFormat }}</h1>

                      <vs-button
                        class="fav-button"
                        radius color="danger"
                        type="flat"
                        :icon="config.favorite == 1 ? 'favorite' : 'favorite_border'"
                        @click="removeAddFromFav(config.favorite == 1 ? 0 : 1, config)"
                        size="large"></vs-button>
                    </div>
                  </vx-card>
                </div>
              </div>
              <div class="p-2"></div>
              <div class="vs-col flex mx-3 mt-6 w-full">
                <h3>{{ $t('SelectQuantity') }}</h3>
                <!--                <vs-input-number min="1" v-model="configQuantity"/>-->
              </div>
              <div class="vs-col flex mx-3 mt-8 mb-6 w-full">
                <vs-spacer></vs-spacer>
                <vs-button @click="configQuantity > 0 ? configQuantity -= 1 : configQuantity = 0; calculateBill()"
                           class="mr-2" size="large"
                           color="danger"
                           type="filled"
                           icon-pack="feather" icon="icon-minus"></vs-button>
                <div class="flix-input-holder">
                  <div class="">
                    <input v-model="configQuantity"
                           type="text"
                           style="font-size: 28px;"
                           @change="calculateBill()"
                           placeholder="0" class="flix-input-field h-auto"
                    >
                  </div>
                </div>
                <!--                      <h4 class="item-card-amount">{{ selectedConfig == config.id ? configQuantity : 0 }}</h4>-->
                <vs-button @click="configQuantity += 1; calculateBill()"
                           class="mx-2" size="large"
                           color="success" type="filled"
                           icon-pack="feather" icon="icon-plus"></vs-button>

                <vs-spacer></vs-spacer>
              </div>
              <div class="p-8"></div>
              <div class="flex justify-evenly w-full card-billing-info text-2xl animate-shake text-danger py-4" v-if="pinQuantityError">
                <p>{{ $t('MaxPinQuantityError') }}</p>
              </div>

              <div class="card-billing-text mx-5 vs-row w-full">
<!--                <div class="  vs-col pr-3 border-0 md:border-r border-solid border-grey-light">-->
<!--                  <p>{{ $t('BuyingPrice') }} <span class="card-billing-info">{{-->
<!--                      (totalBill - profit)| germanNumberFormat-->
<!--                    }}</span></p>-->
<!--                </div>-->
<!--                <vs-spacer></vs-spacer>-->
<!--                <div class=" pl-3 vs-col pr-3 border-0 md:border-r border-solid border-grey-light">-->
<!--                  <p>{{ $t('BuyingDiscount') }} <span class="card-billing-info">{{ discount }} %</span>-->
<!--                  </p>-->
<!--                </div>-->
                <div class="vs-col w-full text-center">
                  <p>{{ $t('SellingPrice') }} <span class="card-billing-info">{{
                      totalBill | germanNumberFormat
                    }}</span>
                  </p>
                </div>
<!--                <div class=" pl-3 vs-col ">-->
<!--                  <p>{{ $t('Profit') }} <span class="card-billing-info">{{ profit | germanNumberFormat }}</span></p>-->
<!--                </div>-->
              </div>


              <div class="p-8 mt-8"></div>
              <div class="vs-row flex justify-center">
                <vs-button size="normal" class="ml-2  mr-4" color="danger" @click="closedShowConfigs()">{{
                    $t('Close')
                  }}
                </vs-button>
                <vs-button size="normal" class="ml-2"
                           color="secondary" @click="purchaseCard()">{{ $t('Buy') }}
                  <strong>{{ configQuantity }}</strong></vs-button>
              </div>
            </div>
          </div>
        </div>
      </vs-sidebar>

    </div>

    <vx-modal v-if="showPurchaseDataModal" component-class="modal-container-fit">
      <template slot="body">
        <div v-if="purchaseData">
          <receipt-print
            v-on:close="closeModal()"
            ref="printCard"
            :print-report-type="0" :purchase-data="purchaseData"></receipt-print>
        </div>
      </template>
    </vx-modal>
  </div>
</template>

<script>
import VxModal from '@/layouts/components/custom/VxModal'
import ReceiptPrint from '@/views/components/ReceiptPrint'

export default {
  name: 'CashCards',
  components: {
    ReceiptPrint,
    VxModal,
  },
  data() {
    return {
      loadingMoreProducts: false,
      showConfigsActive: false,
      selectedProduct: null,
      errorFetching: false,
      configQuantity: 0,
      configPrice: 0,
      selectedConfig: null,
      totalBill: 0,
      discount: 0,
      profit: 0,
      filterAccountStatus: [],
      searchText: '',
      selectedAlpha: null,
      selectedAlphaCurrentX: 1,
      alpha: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
      breadcrumb: [
        {title: 'Home', i18n: 'Home', url: '/'},
        {title: 'Cash Cards', i18n: 'CashCards', active: true},
      ],
      purchaseData: null,
      showPurchaseDataModal: false,
      favCardsToggle: false,
      editFav: false,
      doingTransaction: false,
      pinQuantityError: false,
    }
  },
  computed: {
    cashCards() {
      return this.$store.state.cashCards.cashCards.filter((c) => c.product_name.toLowerCase().startsWith(this.searchText.toLowerCase()))
    },
    favCashCards() {
      return this.$store.state.cashCards.favCashCards.filter((c) => c.product_name.toLowerCase().startsWith(this.searchText.toLowerCase()))
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
  },
  methods: {
    updateFavSequence() {
      const favs = []
      this.favCashCards.forEach((d, index) => {
        favs.push({
          pad_id: d.product_configurations[0].pad_id,
          favorite: 1,
          sequence: index,
        })
      })
      const data = {
        product_type: 'cash card',
        favorite_update: JSON.stringify(favs),
      }
      this.$vs.loading()
      this.$store.dispatch('cashCards/fetchCashCards', data)
        .then((data) => {
          this.$vs.loading.close()
          this.editFav = false
          this.fetchFavCashCards()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    moveCard(event, index) {
      const oldIndex = event.dataTransfer.getData('index')
      this.$store.commit('cashCards/ARRANGE_FAV_CASH_CARDS', {oldIndex: oldIndex, newIndex: index})
    },
    pickUpCard(event, index) {
      console.log(index);
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.setData('index', index)
    },
    closeModal() {
      this.showPurchaseDataModal = false
      this.closedShowConfigs()
      // this.fetchAllCashCards()
    },
    clearSelectedAlpha() {
      this.selectedAlpha = null
      this.selectedAlphaCurrentX = 1

      this.searchText = ''
    },
    pageChanged(newIndex) {
      if (isNaN(newIndex)) {
        this.selectedAlpha = newIndex
        this.selectedAlphaCurrentX = newIndex
        this.searchText = this.selectedAlphaCurrentX
      }
    },
    calculateBill() {
      if (!this.selectedConfig) {
        return
      }


      this.totalBill = this.configQuantity * (this.selectedConfig.value || this.configPrice)
      this.profit = ((this.totalBill) * (this.discount / 100)).toFixed(2)

      const percent = ((this.totalBill) * (this.discount / 100)).toFixed(2)
      this.profit = percent

    },
    addQuantity(config) {
      if (this.selectedConfig == config.id) {
        this.configQuantity = Number(this.configQuantity) + 1
      } else {
        this.discount = config.discount
        this.configPrice = config.value
        this.selectedConfig = config.id
        this.configQuantity = 1
      }
      this.calculateBill()
    },
    // addQuantity() {
    //   if (this.selectedConfig == null) {
    //     this.configQuantity += 1
    //   } else {
    //     this.discount = this.selectedConfig.discount
    //     this.configPrice = this.selectedConfig.value
    //     this.configQuantity += 1
    //   }
    //   this.calculateBill()
    // },
    // removeQuantity() {
    //   if (this.configQuantity >= 1) this.configQuantity -= 1
    //   this.calculateBill()
    // },

    removeQuantity(id) {
      if (this.selectedConfig == id) {
        if (this.configQuantity >= 1) this.configQuantity = Number(this.configQuantity) - 1
      }
      this.calculateBill()
    },
    openConfigsPopUp(p) {
      this.selectedProduct = p
      this.showConfigsActive = true
    },
    closedShowConfigs() {
      this.selectedProduct = null
      this.selectedConfig = null
      this.configQuantity = 1
      this.showConfigsActive = false
      this.totalBill = 0
      this.discount = 0
      this.profit = 0
    },
    removeAddFromFav(what, config) {
      const data = {
        product_type: 'cash card',
        favorite_update: JSON.stringify([
          {
            pad_id: config.pad_id,
            favorite: what,
          },
        ]),
      }
      this.$vs.loading()
      this.$store.dispatch('cashCards/fetchCashCards', data)
        .then((data) => {
          this.$vs.loading.close()
          if (this.selectedProduct) {
            this.cashCards.forEach((card) => {
              if (this.selectedProduct.product_id === card.product_id) {
                this.selectedProduct = card
              }
            })
          }
          this.fetchFavCashCards()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    purchaseCard() {
      this.pinQuantityError = false;
      if(this.doingTransaction) {
        return;
      }
      if (this.selectedConfig == null) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Please select Denomination',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      if (!this.selectedConfig || this.configQuantity <= 0) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: this.$t('ZeroPinQuantityError'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }

      let pinDownload = localStorage.getItem('pin_download') == 'true'
      if(this.configQuantity > 20 && !pinDownload) {
        this.pinQuantityError = true;
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: this.$t('MaxPinQuantityError'),
          color: 'danger',
          iconPack: 'feather',
          icon: 'icon-alert-circle'
        })
        return;
      }

      // if(this.activeUserInfo.download_csv == 0) {
        if (this.configQuantity > 1500) {
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: this.$t('MaxPinQuantityError2'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
          return
        }
      // }

      // if (this.configQuantity > 100) {
      //   this.$vs.notify({
      //     position: 'top-center',
      //     title: 'Error',
      //     text: 'Maximum of 100 quantity',
      //     iconPack: 'feather',
      //     icon: 'icon-alert-circle',
      //     color: 'danger',
      //   })
      //   return
      // }
      const data = {
        product_configuration_id: this.selectedConfig.id || this.selectedConfig,
        quantity: this.configQuantity,
      }
      this.doingTransaction = true;
      this.showConfigsActive = false
      this.$vs.loading()
      this.$store.dispatch('cashCards/purchaseCard', data)
        .then((resData) => {
          this.doingTransaction = false;
          this.$vs.loading.close()
          if(data.quantity > 30 || pinDownload) {
            this.$vs.notify({
              position: 'top-center',
              title: 'Success',
              text: resData.message,
              iconPack: 'feather',
              icon: 'icon-check-circle',
              color: 'success',
            })
          } else {
            this.purchaseData = resData
            this.showPurchaseDataModal = true
            this.closedShowConfigs()
            this.checkPrintCardPop()
          }
        })
        .catch((error) => {
          this.doingTransaction = false;
          this.closedShowConfigs()
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },

    checkPrintCardPop() {
      const showPrintPop = localStorage.getItem('show_print_pop') || 'false'
      if (showPrintPop != 'true') {
        setTimeout(() => {
          this.$refs.printCard.printCard()
        }, 1000)
      }
    },
    fetchFavCashCards() {
      this.$store.dispatch('cashCards/fetchFavCashCards', {})
        .then((data) => {
          this.errorFetching = false
        })
        .catch((error) => {
          console.error(error)
          this.errorFetching = true
          let msg = ''
          try {
            msg = error.response.data.message
          } catch (err) {
            msg = ''
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    fetchAllCashCards(data = {}, loading = true) {
      if(loading) {
        this.$vs.loading()
      }
      this.$store.dispatch('cashCards/fetchCashCardsV2', data)
        .then((data) => {
          if(loading) {
            this.$vs.loading.close()
          }
          this.errorFetching = false

          let totalCount = Number(data.filters.total_count)
          console.log('Total Count: ', totalCount )
          let vPage = Number(data.filters.page)
          let vTotalPages = Math.ceil(Number(data.filters.total_count) / Number(data.filters.limit))
          //  Fixed the issue for double card loading
          // if (vTotalPages > vPage) {
          //   this.loadingMoreProducts = true;
          //   this.fetchAllCashCards({page: vPage + 1}, false)
          // } else {
          //   this.loadingMoreProducts = false;
          // }
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          let msg = ''
          try {
            msg = error.response.data.message
          } catch (err) {
            msg = ''
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    }
  },
  mounted() {
    this.configQuantity = 1
    if (localStorage.getItem('merge_receipts') == null) {
      localStorage.setItem('merge_receipts', 'false')
    }
    this.mergeReceipts = localStorage.getItem('merge_receipts') == 'true'
  },
  created() {
    if(this.cashCards.length <= 0) {
      this.fetchAllCashCards()
    }
    if(this.favCashCards.length <= 0) {
      this.fetchFavCashCards()
    }
    this.$store.dispatch("auth/fetchTransactionKey");
  },
}
</script>

<style lang="scss">
.cash-cards-side-bar {
  .vs-sidebar--items {
    overflow-y: scroll !important;
  }
}
#cash-cards-sidebar {
  .vs-sidebar--items {
    overflow-y: scroll !important;
  }
  .vs-slider {
    width: 100%;
    height: 10px;
    border-radius: 4px;
    background: #f0f0f0;
    position: relative;
    cursor: pointer;
    border: 0;
    margin: 0;
    display: block;
    overflow: hidden;
  }

  .vs-slider--circle {
    width: 24px;
    height: 24px;
    position: absolute;
    top: -6px;
    border-radius: 50%;
    cursor: pointer;
    border: 0;
    margin: 0;
    z-index: 200;
  }

  .vs-slider--circle-text {
    padding: 8px 10px;
    border-radius: 10px;
    font-size: 1.75rem;
  }

  .vs-slider--circles .text-circle-slider {
    opacity: 1;
    -webkit-transform: translate(-50%, calc(-100% + -13px)) scale(1) rotate(0deg);
    transform: translate(-50%, calc(-100% + -13px)) scale(1) rotate(0deg);
    visibility: visible;
  }
}

.modal-container {
  border-radius: 30px !important;
}

.card-image {
  max-height: 48px;
  object-fit: cover;
  margin: auto;
}

.modal-card-image {
  max-height: 80px;
  background: white;
  margin: 0 auto;
  border-radius: 12px;
}

.item-card-text {
  font-size: 24px;
  font-weight: bolder;
}

.item-card-amount {
  font-size: 36px;
  padding: 0 10px;
}

.card-billing-text {
  font-size: 20px;
  font-weight: bold;
}
.custom-card-value {
  font-size: 24px !important;
}
.card-billing-info {
  font-weight: normal !important;
}
.custom-single-card {
  .vx-card__body {
    padding: 0.5rem 0 !important;
  }
}

.single-cash-card {
  width: 160px;
}

.product-selected {
  background-color: #44D7B6 !important;
  color: #ffffff !important;
}

.product-selected .item-card-profit {
  color: #ffffff !important;
}

.product-selected .item-card-text {
  color: #ffffff !important;
}
.flix-input-holder {
  width: 42px;
  padding: 0;
  margin: 0;
}

.flix-input-field {
  height: 32px;
  border: 1px solid #c8c8c8;
  text-align: center;
  width: 100%;
  text-indent: 0;
  border-radius: 5px;
  font-size: 24px;
}


.bg-dark-color {
  background-color: #10163a;
}

.h-38 {
  height: 38px;
}

.theme-dark .custom-input {
  color: black !important;
  background: none !important;
}



</style>
